.notification-list {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .notification-item {
    padding: 12px 16px !important;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  .notification-item:hover {
    background-color: #f0f2f5;
  }
  
  .notification-content {
    display: flex;
    align-items: flex-start;
  }
  
  .notification-icon {
    font-size: 20px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f0f2f5;
    flex-shrink: 0;
  }
  
  .notification-text {
    flex: 1;
  }
  
  .notification-title {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 4px;
  }
  
  .notification-task-title {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 2px;
  }
  
  .notification-task-excerpt {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 4px;
  }
  
  .notification-time {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
  
  .empty-notification {
    text-align: center;
    padding: 20px;
    color: rgba(0, 0, 0, 0.45);
  }
  
  /* Customize scrollbar for webkit browsers */
  .notification-list::-webkit-scrollbar {
    width: 6px;
  }
  
  .notification-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  .notification-list::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }