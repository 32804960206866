.social-updates-container {
    padding-top: 24px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .page-header {
    margin-bottom: 24px;
  }
  
  .update-form {
    background-color: #f0f2f5;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 24px;
  }
  
  .users-card {
    position: sticky;
    top: 84px; /* Adjust this value based on your navbar height */
  }
  
  .updates-list {
    margin-top: 24px;
  }
  
  /* Adjust these values based on your navbar height */
  @media (min-width: 768px) {
    .social-updates-container {
      padding-top: 64px;
    }
  
    .users-card {
      top: 124px;
    }
  }
  
  .bg-blue-50 {
    background-color: #e6f7ff;
  }